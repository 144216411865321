import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Helmet from 'react-helmet'
import icon from '../img/fav_icon.ico'

import "../styles/bootstrap.min.css";
import "../styles/fontawesome.min.css";
import "../styles/solid.min.css";
import '../styles/themeStyle.scss'
import '../styles/Styles.css'
import '../styles/components/navbar.css'
import '../styles/components/footer.css'
import '../styles/page/main.scss'

const TemplateWrapper = ({ children }) => {
  return (
    <div className="theme-blue">
      <Helmet>
      <link rel="icon" type="image/x-icon" href={icon} />
      </Helmet>
      <Navbar />
      <div>{children}
      {/* <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K554NFQ" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript> */}
      </div>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
