import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/netart_logo.png'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg'
import youtube from '../img/social/youtube.svg'
import pinterest from '../img/social/pinterest.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <React.Fragment>
        <footer  className="footer">
          <div  className="container">
            <div  className="row">
              <div  className="col-lg-3 margin-t-20">
              <Link className="navbar-brand logo-size" to="/">
              <img src={logo} alt="logo" />
            </Link>
              </div>

              <div  className="col-lg-3 margin-t-20">
                <h4 >Address</h4>
                <div  className="text-muted margin-t-20">
                  A.K Complex, 13/17, Tagore Rd,Ganapathy, Coimbatore, <br/>Tamil Nadu-641006
                </div>
              </div>

              <div  className="col-lg-3 margin-t-20">
                <h4 >Support</h4>
                <div  className="text-muted margin-t-20">
                 
                </div>
              </div>

              <div  className="col-lg-3 margin-t-20" >
              <h4 >Services</h4>
                 <ul className="text-muted margin-t-20">
                  <li>SEO SERVICES</li>
                  <li>WEB DESIGN SERVICES</li>
                  <li>FREELANCING SERVICES</li>
                  <li>OUR WEB DESIGNERS</li>
                  <li>OUR SEO SPECILISTS</li>
                  <li>WEB APPLICATION DEVELOPMENT</li>
                 </ul>
              </div>
             
            </div>
          </div>
        </footer>

        <div  className="footer-alt">
          <div  className="container">
            <div  className="row">
              <div  className="col-lg-12">
                <div  className="float-left pull-none ">
                  <p  className="copy-rights text-muted">
                    2019 © <a href="https://netart.in/" target="_blank" rel="noopener noreferrer"><span className="net">Net</span><span className="art">Art</span> </a>
                  </p>
                </div>
                <div className="float-right pull-none footer-social">
                  <a
                    title="facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/Netart-226802998259697/"
                  >
                    <img
                      src={facebook}
                      alt="Facebook"
                      style={{height: "2rem" }}
                    />
                  </a>
                  <a
                    title="twitter"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://twitter.com/NetartW"
                  >
                    <img
                      className="fas fa-lg"
                      src={twitter}
                      alt="Twitter"
                      style={{height: "2rem" }}
                    />
                  </a>
                  <a
                    title="pinterest"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://in.pinterest.com/netartcbe/"
                  >
                    <img
                      src={pinterest}
                      alt="pinterest"
                      style={{height: "2rem" }}
                    />
                  </a>
                  <a
                    title="instagram"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/netartseo/"
                  >
                    <img
                      src={instagram}
                      alt="instagram"
                      style={{height: "2rem" }}
                    />
                  </a>
                  <a
                    title="youtube"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/channel/UCyH_Nrlu6UlvCcatsH-jmrg/about?view_as=subscriber"
                  >
                    <img
                      src={youtube}
                      alt="youtube"
                      style={{height: "2rem" }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Footer
