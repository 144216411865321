import React from "react";
import logo from "../img/netart_logo.png";
import $ from 'jquery';

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(){
    $(window).scroll(function() {
      var scroll = $(window).scrollTop();
       //>=, not <=
      if (scroll >= 100) {
          $(".fixed-top").css({
            'background-color': 'rgb(39, 42, 51)',
            'padding' : '10px'
        });
      }
      else{
        $(".fixed-top").css({
          'padding' : '20px',
          'background-color': 'transparent',
      });
      }
  });


  $(".navbar-nav a").on("click", function(event) {
    var $anchor = $(this);
    $("html, body")
      .stop()
      .animate(
        { scrollTop: $($anchor.attr("href")).offset().top - 0 },
        1500);
    event.preventDefault();
  });

  }
toggleClass = () =>{
  $("#navbarCollapse").toggleClass("menu-show");
}
  render() {
    return (
      <React.Fragment>
         <nav className="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" id="navbar1"
          style={{ padding: "20px" }} >
          <div className="container">
            <img className="navbar-brand logo-size" src={logo} alt="logo" />
            <button aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation"
              className="navbar-toggler" data-target="#navbarCollapse" data-toggle="collapse" 
              id="menu_button" type="button" onClick={this.toggleClass} >
              <i className="fa fa-bars" aria-hidden="true" />
            </button>

            <div className="collapse navbar-collapse" id="navbarCollapse">
              <ul className="navbar-nav navbar-right ml-auto" id="mySidenav">
                <li className="nav-item">
                  <a href="#home" className="nav-link" style={{ color: "#fff" }} > Home </a>
                </li>
                <li className="nav-item">
                  <a href="#services" className="nav-link" > Services </a>
                </li>
                <li className="nav-item">
                  <a href="#contact" className="nav-link"> Contact </a>
                </li>
                <li className="nav-item offer_link">
                  <span href="#" className="span nav-link"> Offers </span>
                  <div className="offer_content">
                  <h3>Offers</h3>
                  <table>
                    <tbody>
                  <tr>
                    <td><span className="service">seo</span></td>
                    <td> - </td>
                    <td><span className="new_price">Rs.4999</span></td>
                    <td><span className="old_price">Rs.7000</span></td>
                  </tr>
                  <tr>
                    <td> <span className="service">web design</span></td>
                    <td> - </td>
                    <td><span className="new_price">Rs.3999</span></td>
                    <td><span className="old_price">Rs.5000</span></td>
                  </tr>
                  <tr>
                    <td><span className="service">digital marketing</span></td>
                    <td> - </td>
                    <td><span className="new_price">Rs.4999</span></td>
                    <td><span className="old_price">Rs.7000</span></td>
                  </tr>
                  </tbody>
                  </table>
                </div>
                </li>
                <li className="nav-item">
                  <button className="call_to_action">
                  <span title="Call to +91 9789720718" href="tel:+919789720718" className="nav-link"> +91-9789720718 </span>
                  </button>
                </li>
                <li className="nav-item">
                <button className="call_to_action">
                  <span title="mailto:work@netart.io" href="mailto:work@netart.io" className="nav-link"> work@netart.io </span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
};

export default Navbar;
